<template>
  <div>
    <tabla-generica 
    ref="tg"
    title = "Ciudades"
    :rows = 'ciudades' 
    :titles = 'titulos' 
    :columns = 'columnas'    
    @newRecord = 'nuevo'
    @editRecord = 'editar'
    @rowSelect = 'seleccionar'
    ></tabla-generica>
    
    <!-- Modal Component -->
    <b-modal 
    id="modal1" 
    title="Tabla de Ciudades"
    cancel-title = " No "
    ok-title=" Si "
    @ok="borrar">
      <p class="my-4">Desea eliminar el ciudad?</p>
    </b-modal>
  </div>
</template>

<script>
  import axios from 'axios';
  import TablaGenerica from '@/components/tools/TablaGenerica.vue';

  export default {
    mounted: function() {
        this.getZonas();
        this.getCiudades();
      },
    components: {
      TablaGenerica
    },
    data() {
      return {
        rowId: undefined,
        zonas: [],
        ciudades2: [],
        ciudades: [],
        regiones: [{'id':'XXXX','nombre': 'NO ASIGNADA'},{'id': 'TSUR', 'nombre': 'TICKETS SUR'}, {'id': 'TBAJ', 'nombre': 'TICKETS BAJIO'}, {'id': 'TNOR', 'nombre': 'TICKETS NORTE'}],
        titulos: ['ID', 'NOMBRE', 'ESTADO', 'ZONA', 'REGION'],        
        columnas: ['id', 'nombre', 'estado','zona', 'region'],        
        textoBuscar: 'bajio'
      }
    },
    created() {
    },
    updated() {
      this.validar();
    },
    methods: {
      validar(){
        //console.log( this.$store.state.textCiudades );
        this.$refs.tg.setTexto(this.$store.state.textCiudades);          
        }, 
      getZonas() {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
        axios.get(process.env.VUE_APP_ROOT_API + "api/secure/zonas").then((response) => {                                              
          this.zonas = response.data
        }, (error) => {              

        })
      },
      getCiudades: function() {
          var token =  this.$cookies.get('token');
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          axios.get(process.env.VUE_APP_ROOT_API + "api/secure/ciudades").then((response) => {                        
            this.ciudades2 = JSON.parse(JSON.stringify(response.data));           
            this.ciudades = [];
            var items = response.data;              
            for (let item of items) {                                
              item.zona = JSON.stringify(item.zonaa)==='null'?'':item.zonaa.nombre;  
              item.region = JSON.stringify(item.region)==='null' ? '' : this.regiones.find(row => row.id === item.region).nombre;           
              this.ciudades.push(item)               
            }
            this.ciudades = response.data            
          }, (error) => {              
  
          })
        },             
        seleccionar(row){
          this.rowId = row.id;          
        },        
        nuevo: function(){
          this.$router.push({ name: 'Ciudad', params: { id: 0, ciudad: {id: null, nombre: null}  }})            
        },
        editar: function(){
          if (!(this.rowId === undefined)){               
            this.$router.push({ name: 'Ciudad', params: { id: this.rowId, ciudad: this.ciudades2.find(row => row.id === this.rowId), zonas: this.zonas}})            
          }
        },        
        borrar: function() {
          if (!(this.rowId === undefined)){                 
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.delete(process.env.VUE_APP_ROOT_API + "api/secure/ciudades/" + this.rowId)
            .then((response) => {                      
              this.getCiudades();
            }, (error) => {          
            })
          }
        },
    }
  }
</script>

<style>  
</style>